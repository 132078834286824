export const yearConstants = [
  { name: "2023", value: 2023 },
  { name: "2024", value: 2024 },
  { name: "2025", value: 2025 },
  { name: "2026", value: 2026 },
  { name: "2027", value: 2027 },
  { name: "2028", value: 2028 },
  { name: "2029", value: 2029 },
  { name: "2030", value: 2030 },
];
