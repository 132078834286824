<template>
  <b-card>
    <div>
      <!-- search input -->
      <b-row>
        <b-col md="6" lg="6" xs="12">
          <v-select
            label="name"
            v-model="filterYear"
            :options="yearConstants"
            :reduce="(item) => item.value"
            v-on:input="filterByYear"
            placeholder="Filter By Year"
            class="mr-sm-1 mb-1 mb-sm-0 custom-font"
          />
        </b-col>
        <b-col md="6" lg="6" xs="12">
          <v-select
            label="name"
            v-model="filterMonth"
            :options="monthNameConstants"
            :reduce="(item) => item.value"
            v-on:input="filterByMonth"
            placeholder="Filter By Month "
            class="mr-sm-1 mb-1 mb-sm-0 custom-font"
          />
        </b-col>
      </b-row>
      <div
        class="custom-search d-flex align-items-center justify-content-end mt-1"
      >
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <!-- <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          /> -->

          <b-button
            class="flex-shrink-0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{ name: 'admin-settings-weekends-calender' }"
          >
            Calender View
          </b-button>

          <template
            v-if="
              $permissionAbility(WEEKENDS_DELETE, permissions) &&
              isMultipleRowChecked
            "
          >
            <b-button
              class="flex-shrink-0 ml-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-on:click="deleteSelected"
            >
              Delete Selected
            </b-button>
          </template>

          <template v-if="$permissionAbility(WEEKENDS_CREATE, permissions)">
            <b-button
              class="flex-shrink-0 ml-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
        mode="remote"
        style-class="vgt-table table-custom-style bordered condensed"
        @on-selected-rows-change="selectionChanged"
        @on-per-page-change="onPerPageChange"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'id', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :select-options="{ enabled: true }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: employee type -->
          <span v-if="props.column.field === 'format_date'">
            {{ formatWeekDay(props.row?.date) }},
            {{ formatDateTime(props.row?.date) }}
          </span>

          <span v-if="props.column.field === 'format_description'">
            <template>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="showDescription(props.row?.description)"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </template>
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!-- <template v-if="$permissionAbility(WEEKENDS_EDIT, permissions)">
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template> -->

                <template
                  v-if="$permissionAbility(WEEKENDS_DELETE, permissions)"
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="modal-description-view"
      centered
      title="Description"
      hide-footer
      @hidden="hiddenDescriptionModal"
      no-close-on-backdrop
    >
      {{ description }}
    </b-modal>
    <b-modal
      id="modal-weekends-form"
      centered
      :title="modelType == 'editModel' ? 'Edit' : 'Create'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="weekendsValidation">
        <b-form v-on:submit.prevent="validationForm">
          <b-form-group
            label="Select Year"
            label-for="year"
            :class="isCustomDateSelected ? '' : 'required-label'"
          >
            <ValidationProvider
              name="year"
              v-slot="{ errors }"
              vid="year"
              :rules="isCustomDateSelected ? '' : 'required'"
            >
              <v-select
                id="year"
                v-model="year"
                :options="yearConstants"
                :reduce="(item) => item.value"
                label="name"
                placeholder="Choose Year"
                class="custom-font"
                :disabled="isCustomDateSelected ? true : false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
          <b-form-checkbox
            v-model="isFullYearSelected"
            :disabled="isCustomDateSelected ? true : false"
          >
            Set Default Weekends for Unset Months
          </b-form-checkbox>
          <b-form-group
            label="Select Month"
            label-for="month"
            :class="
              isCustomDateSelected || isFullYearSelected
                ? 'mt-1'
                : 'required-label mt-1'
            "
          >
            <ValidationProvider
              name="month"
              v-slot="{ errors }"
              vid="month"
              :rules="
                isCustomDateSelected || isFullYearSelected ? '' : 'required'
              "
            >
              <v-select
                id="month"
                v-model="monthNumber"
                :options="monthNameConstants"
                :reduce="(item) => item.value"
                label="name"
                placeholder="Choose Month Name"
                class="custom-font"
                :disabled="
                  isCustomDateSelected || isFullYearSelected ? true : false
                "
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            label="Days"
            label-for="day"
            :class="isCustomDateSelected ? '' : 'required-label'"
          >
            <ValidationProvider
              name="days"
              v-slot="{ errors }"
              vid="days"
              :rules="isCustomDateSelected ? '' : 'required'"
            >
              <v-select
                v-model="selectedDays"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :reduce="(item) => item.value"
                label="name"
                :options="weekDayConstants"
                placeholder="Select Weekend Days"
                :disabled="isCustomDateSelected ? true : false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-checkbox
            v-model="isCustomDateSelected"
            :disabled="isFullYearSelected ? true : false"
          >
            Set Custom Date
          </b-form-checkbox>

          <b-form-group
            label="Custom Date"
            label-for="date"
            :class="isCustomDateSelected ? 'required-label mt-1' : 'mt-1'"
          >
            <ValidationProvider
              name="date"
              v-slot="{ errors }"
              vid="date"
              :rules="isCustomDateSelected ? 'required' : ''"
            >
              <b-form-datepicker
                id="date"
                v-model="date"
                :state="errors.length > 0 ? false : null"
                locale="en-US"
                reset-button
                @context="onContextDate"
                :date-disabled-fn="dateDisabled"
                :disabled="isCustomDateSelected ? false : true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
          <!-- description -->
          <b-form-group label="Description" label-for="description">
            <validation-provider
              #default="{ errors }"
              name="description"
              vid="description"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                :state="errors.length > 0 ? false : null"
                placeholder="Description"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isWeekendFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { monthNameConstants } from "@/helpers/constant/monthNameConstant";
import { weekDayConstants } from "@/helpers/constant/weekDayConstant";
import { yearConstants } from "@/helpers/constant/yearConstant";
import {
  WEEKENDS_CREATE,
  WEEKENDS_EDIT,
  WEEKENDS_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "WeekendsView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BRow,
    BCol,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      WEEKENDS_CREATE,
      WEEKENDS_EDIT,
      WEEKENDS_DELETE,

      modelType: "",
      weekendId: "",
      description: "",
      monthNumber: "",
      monthNameConstants,
      yearConstants,
      weekDayConstants,
      selectedDays: [],
      year: "",
      isCustomDateSelected: false,
      isFullYearSelected: false,
      date: "",

      isMultipleRowChecked: false,
      selectedRows: [],
      //filter
      filterYear: "",
      filterMonth: "",

      isWeekendFormSubmitLoading: false,
      weekends: [],

      // table
      pageLength: 10,
      columns: [
        {
          label: "Date",
          field: "format_date",
          sortable: false,
        },
        {
          label: "Description",
          field: "format_description",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatFnTableFormateDate,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],

      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
      // table
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    getFilterYear() {
      return new Date().getFullYear();
    }
  },

  async created() {
    try {
      this.setCurrentYear();
      await this.getAllWeekends();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Errorx",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async deleteSelected() {
      const weekendIds = (this.selectedRows || []).map((item) => {
        return item?.id;
      });

      this.$swal({
        title: "Warning!",
        text: "Are you sure to delete selected weekends?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.post("api/weekends/delete-multiple", {
              weekend_ids: weekendIds,
            });

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Weekends Successfully Deleted ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    selectionChanged(params) {
      if (params?.selectedRows.length == 0) {
        this.isMultipleRowChecked = false;
        this.selectedRows = [];
      } else {
        this.isMultipleRowChecked = true;
        this.selectedRows = params?.selectedRows;
      }
    },

    showDescription(value) {
      if (value) {
        this.description = value;
      } else {
        this.description = "Not Set";
      }

      this.showDescriptionModal();
    },
    showDescriptionModal() {
      this.$bvModal.show("modal-description-view");
    },
    hiddenDescriptionModal() {
      this.description = "";
      this.$bvModal.hide("modal-description-view");
    },
    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    formatWeekDay(value) {
      if (value) {
        return this.$moment(value).format("dddd");
      }
      return "Not Set";
    },
    setCurrentYear() {
      this.filterYear = this.getFilterYear; // Accessing computed property
    },


    async getAllWeekends(params) {
      try {
        const getAllWeekends = await this.$api.get("api/weekends/all");

        this.weekends = (getAllWeekends?.data?.data || []).map((item) => {
          return item?.date;
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    dateDisabled(ymd, date) {
      return this.weekends.includes(ymd);
    },
    onContextDate(ctx) {
      this.date = ctx.selectedYMD;
    },
    formatFnTableFormateDate(value) {
      if (value) {
        return this.$moment(value).format("ll");
      }
    },
    showModal() {
      this.$bvModal.show("modal-weekends-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-weekends-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.weekendId = "";
      this.monthNumber = "";
      this.selectedDays = [];
      this.description = "";
      this.year = "";
      this.isCustomDateSelected = false;
      this.date = "";
      this.isFullYearSelected = false;
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.weekendId = value?.id;
      this.description = value?.description;
      this.monthNumber = value?.month_number;
      this.year = value?.year;
      this.selectedDays = value?.days;

      this.showModal();
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/weekends/${id}`);

            this.loadItems();
            this.getAllWeekends();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getWeekends(params) {
      return await this.$api.get("api/weekends", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          filterMonth: this.filterMonth,
          filterYear: this.filterYear,
        },
      });
    },
    // async getEmployeeTypes() {
    //   return await this.$api.get("api/employee-types/all");
    // },

    filterByYear() {
      this.loadItems();
    },

    filterByMonth() {
      this.loadItems();
    },

    async loadItems() {
      try {
        const [weekends] = await Promise.all([
          this.getWeekends({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterMonth: this.filterMonth,
            filterYear: this.filterYear,
          }),
        ]);

        const data = weekends?.data?.data;
        const meta = weekends?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message || "An unknown error occurred.",
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.weekendsValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isWeekendFormSubmitLoading = true;
              await this.$api.put(`/api/weekends/${this.weekendId}`, {
                date: this.date,
                description: this.description,
              });
              this.isWeekendFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isWeekendFormSubmitLoading = true;

              if (this.isCustomDateSelected) {
                await this.$api.post("api/weekends/custom", {
                  description: this.description,
                  date: this.date,
                });
              } else if (this.isFullYearSelected) {
                await this.$api.post("api/weekends/full-year", {
                  description: this.description,
                  selected_days: this.selectedDays,
                  year: this.year,
                });
              } else {
                await this.$api.post("/api/weekends", {
                  month_number: this.monthNumber,
                  description: this.description,
                  selected_days: this.selectedDays,
                  year: this.year,
                });
              }

              this.isWeekendFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();
              this.getAllWeekends();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isWeekendFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.weekendsValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.required-label label::after {
  content: " *";
  color: red;
}
.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
</style>
